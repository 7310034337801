import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import PaidTicketInfoGet from "@/store/analysis/paid-ticket-info/get";
import PaidTicketPeriodMonthlyGet from "@/store/analysis/paid-ticket-period-monthly/get";
import PaidTicketPeriodWeeklyGet from "@/store/analysis/paid-ticket-period-weekly/get";
import Admin from "@/store/admin/admin";
import { PaidTicketInfoGetItem } from "@/api/analysis/paid-ticket-info/response";
import { PaidTicketPeriodGetRequest } from "@/api/analysis/paid-ticket-period/request";
import * as paidTicketPeriodCSV from "@/api/analysis/paid-ticket-period-csv";
import { PaidTicketInputOptions } from "@/models/analysis/digital-ticket";

export interface PaidTicketPeriodInputOptions {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  selectShop: { id: string; name: string } | null;
  selectTicket: { id: number; name: string } | null;
}

@Component({
  components: {
    UlContentHeader
  }
})
export default class AnalysisPaidTicketPeriod extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "デジタルチケット期間別分析";
  headingSub = "Digital ticket";
  breadCrumbs = [{ text: "デジタルチケット期間別分析", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedTicketData = false;
  isLoadedPaidTicketPeriodMonthlyData = false;
  isLoadedPaidTicketPeriodWeeklyData = false;

  // 検索入力オプション
  paidTicketPeriodInputOptions: PaidTicketPeriodInputOptions = {
    startYear: Number(this.years[0]),
    startMonth: this.months[new Date().getMonth()],
    endYear: Number(this.years[0]),
    endMonth: this.months[new Date().getMonth()],
    selectShop: null,
    selectTicket: null
  };

  paidTicketPeriodWeeklyInputOptions = {
    month: 0,
    year: 0,
    date: ""
  };

  selectShopId = "";

  selectedTicket: PaidTicketInfoGetItem = {} as PaidTicketInfoGetItem;

  @Watch("paidTicketPeriodInputOptions", { deep: true })
  paidTicketInputOptionsDidChange(inputOptions: PaidTicketInputOptions) {
    if (inputOptions.selectTicket == null) {
      this.selectedTicket = {} as PaidTicketInfoGetItem;
      return;
    }
    const selectedTicket = this.ticketItems.find(t => {
      return (
        (inputOptions.selectTicket != null
          ? inputOptions.selectTicket.id
          : null) == t.id
      );
    });
    if (selectedTicket) {
      this.selectedTicket = selectedTicket;
    }
  }

  @Watch("paidTicketPeriodInputOptions.selectShop")
  async selectShopDidChange(selectShop: { id: string; name: string } | null) {
    this.paidTicketPeriodInputOptions.selectTicket = null;
    if (selectShop) {
      await this.fetchTicketList();
    } else {
      await PaidTicketInfoGet.clearResponse();
    }
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get ticketItems() {
    return PaidTicketInfoGet.getItems;
  }

  get periodMonthlyItems() {
    return PaidTicketPeriodMonthlyGet.getItems;
  }

  get periodWeeklyItems() {
    return PaidTicketPeriodWeeklyGet.getItems;
  }

  get years() {
    let years = [];
    for (let i = 0; i <= 10; i++) {
      let date = new Date();
      date.setFullYear(date.getFullYear() - i);
      years.push(date.getFullYear());
    }
    return years;
  }

  get months() {
    return Array.from(new Array(12)).map((v, i) => i + 1);
  }

  get tableHeaders() {
    let headers = [
      { text: "該当月/週", value: "date" },
      { text: "売上", value: "salesPrice" },
      { text: "販売実績", value: "salesTicketValue" },
      { text: "利用枚数", value: "useUserValue" },
      { text: "利用率", value: "useRate" }
    ];

    if (this.selectedTicket.ticketType == 2) {
      headers = [
        { text: "該当月/週", value: "date" },
        { text: "売上", value: "salesPrice" },
        { text: "販売実績", value: "salesTicketValue" },
        { text: "有効枚数", value: "validTicketValue" },
        { text: "利用回数", value: "useTicketValue" },
        { text: "利用率", value: "useRate" }
      ];
    } else if (
      this.selectedTicket.ticketType == 3 &&
      this.selectedTicket.subscriptionType == 1
    ) {
      headers = [
        { text: "該当月/週", value: "date" },
        { text: "売上", value: "salesPrice" },
        { text: "新規契約数", value: "salesTicketValue" },
        { text: "累計契約数", value: "contractValue" },
        { text: "単価/枚", value: "unitPrice" },
        { text: "契約更新数", value: "continueValue" },
        { text: "利用回数", value: "useTicketValue" },
        { text: "利用率", value: "useRate" }
      ];
    } else if (
      this.selectedTicket.ticketType == 3 &&
      this.selectedTicket.subscriptionType == 2
    ) {
      headers = [
        { text: "該当月/週", value: "date" },
        { text: "売上", value: "salesPrice" },
        { text: "販売実績", value: "salesTicketValue" },
        { text: "利用枚数", value: "useUserValue" },
        { text: "利用率", value: "useRate" }
      ];
    }
    return headers;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.paidTicketPeriodInputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchTicketList();
      this.paidTicketPeriodInputOptions.selectTicket = {
        id: PaidTicketInfoGet.getItems[0].id,
        name: PaidTicketInfoGet.getItems[0].title
      };
      this.selectedTicket = this.ticketItems[0];
      await this.fetchTicketPeriodMonthly();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await PaidTicketInfoGet.clearResponse();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  async fetchTicketListAndPaidTicket() {
    /*NEW_DEV-2072 kubota add start*/
    this.paidTicketPeriodWeeklyInputOptions.month = 0;
    this.paidTicketPeriodWeeklyInputOptions.year = 0;
    this.paidTicketPeriodWeeklyInputOptions.date = "";
    /*NEW_DEV-2072 kubota add end*/

    await this.fetchTicketPeriodMonthly();
  }

  /**
   * デジタルチケット情報の一覧を取得する処理
   */
  async fetchTicketList(): Promise<boolean> {
    this.isLoadedTicketData = false;
    await Flash.clear();

    await PaidTicketInfoGet.get({
      shopId:
        this.paidTicketPeriodInputOptions.selectShop != null
          ? Number(this.paidTicketPeriodInputOptions.selectShop.id)
          : null
    });
    if (!PaidTicketInfoGet.isSuccess) {
      await Flash.setErrorNow({
        message: PaidTicketInfoGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedTicketData = true;
    return PaidTicketInfoGet.isSuccess;
  }

  async fetchTicketPeriodMonthly(): Promise<boolean> {
    this.isLoadedPaidTicketPeriodMonthlyData = false;
    await Flash.clear();

    const request: PaidTicketPeriodGetRequest = {
      startYear: this.paidTicketPeriodInputOptions.startYear,
      startMonth: this.paidTicketPeriodInputOptions.startMonth,
      endYear: this.paidTicketPeriodInputOptions.endYear,
      endMonth: this.paidTicketPeriodInputOptions.endMonth,
      searchShopId:
        this.paidTicketPeriodInputOptions.selectShop != null
          ? Number(this.paidTicketPeriodInputOptions.selectShop.id)
          : null,
      searchTicketId:
        this.paidTicketPeriodInputOptions.selectTicket != null
          ? this.paidTicketPeriodInputOptions.selectTicket.id
          : null,
      viewType: 2
    };

    await PaidTicketPeriodMonthlyGet.get(request);
    if (!PaidTicketPeriodMonthlyGet.isSuccess) {
      await Flash.setErrorNow({
        message: PaidTicketPeriodMonthlyGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPaidTicketPeriodMonthlyData = true;
    return PaidTicketPeriodMonthlyGet.isSuccess;
  }

  async fetchTicketPeriodWeekly(): Promise<boolean> {
    if (
      this.paidTicketPeriodInputOptions.selectShop == null ||
      this.paidTicketPeriodInputOptions.selectTicket == null
    ) {
      return false;
    }

    this.isLoadedPaidTicketPeriodWeeklyData = false;

    const request: PaidTicketPeriodGetRequest = {
      startYear: this.paidTicketPeriodWeeklyInputOptions.year,
      startMonth: this.paidTicketPeriodWeeklyInputOptions.month,
      endYear: this.paidTicketPeriodWeeklyInputOptions.year,
      endMonth: this.paidTicketPeriodWeeklyInputOptions.month,
      searchShopId: Number(this.paidTicketPeriodInputOptions.selectShop.id),
      searchTicketId: this.paidTicketPeriodInputOptions.selectTicket.id,
      viewType: 7,
      date: this.paidTicketPeriodWeeklyInputOptions.date
    };

    await PaidTicketPeriodWeeklyGet.get(request);
    if (!PaidTicketPeriodWeeklyGet.isSuccess) {
      await Flash.setErrorNow({
        message: PaidTicketPeriodWeeklyGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPaidTicketPeriodWeeklyData = false;
    return PaidTicketPeriodWeeklyGet.isSuccess;
  }

  async onClickRow(date: string) {
    const year = Number(date.split("年")[0]);
    const month = Number(date.split("年")[1].replace("月", ""));
    // NEW_DEV-2052 cyber start
    if (
      this.paidTicketPeriodWeeklyInputOptions.year != year ||
      this.paidTicketPeriodWeeklyInputOptions.month != month ||
      !this.isLoadedPaidTicketPeriodWeeklyData
      ) {
    // NEW_DEV-2052 cyber end
      this.paidTicketPeriodWeeklyInputOptions.year = year;
      this.paidTicketPeriodWeeklyInputOptions.month = month;
      this.paidTicketPeriodWeeklyInputOptions.date =
        year + "/" + ("0" + month).slice(-2);
      await this.fetchTicketPeriodWeekly();
    }
  }

  async fetchPaidTicketPeriodCSV() {
    const request: PaidTicketPeriodGetRequest = {
      startYear: this.paidTicketPeriodInputOptions.startYear,
      startMonth: this.paidTicketPeriodInputOptions.startMonth,
      endYear: this.paidTicketPeriodInputOptions.endYear,
      endMonth: this.paidTicketPeriodInputOptions.endMonth,
      searchShopId:
        this.paidTicketPeriodInputOptions.selectShop != null
          ? Number(this.paidTicketPeriodInputOptions.selectShop.id)
          : null,
      searchTicketId:
        this.paidTicketPeriodInputOptions.selectTicket != null
          ? this.paidTicketPeriodInputOptions.selectTicket.id
          : null,
      viewType: 2
    };
    delete request.viewType;

    const paidTicketPeriodGetResponse = await paidTicketPeriodCSV.get(request);
    if (
      paidTicketPeriodGetResponse &&
      paidTicketPeriodGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: paidTicketPeriodGetResponse.message,
        showReloadButton: false
      } as ErrorAlert);
      return false;
    }

    this.saveCSV(
      paidTicketPeriodGetResponse.results.csvString,
      paidTicketPeriodGetResponse.results.fileName
    );
  }
}
