import service from "@/api/service";
import { PaidTicketPeriodGetRequest } from "@/api/analysis/paid-ticket-period/request";
import { PaidTicketPeriodGetResponse } from "@/api/analysis/paid-ticket-period/response";

/**
 * デジタルチケット期間別分析取得APIをコールします。
 *
 * @param getRequest デジタルチケット期間別分析取得APIのリクエストボディ
 * @return PaidTicketPeriodGetResponse
 */
export async function get(getRequest: PaidTicketPeriodGetRequest) {
  const response = await service.post(
    "/analysis-paid-ticket-period",
    getRequest
  );
  return response.data as PaidTicketPeriodGetResponse;
}
