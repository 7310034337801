import service from "@/api/service";
import { PaidTicketPeriodGetRequest } from "@/api/analysis/paid-ticket-period/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * デジタルチケット期間別分析CSV出力APIをコールします。
 *
 * @param getRequest デジタルチケット期間別分析CSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: PaidTicketPeriodGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-paid-ticket-period-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
