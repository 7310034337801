import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as PaidTicketPeriodAPI from "@/api/analysis/paid-ticket-period";
import { isSuccess } from "@/api/response";
import { PaidTicketPeriodGetRequest } from "@/api/analysis/paid-ticket-period/request";
import { PaidTicketPeriodGetResponse } from "@/api/analysis/paid-ticket-period/response";

const MODULE_NAME = "analysis/paid-ticket-period-monthly/get";

/**
 *  デジタルチケット期間別分析取得API（/analysis-paid-ticket-period）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PaidTicketPeriodGetRequest = {} as PaidTicketPeriodGetRequest;
  getResponse: PaidTicketPeriodGetResponse = {} as PaidTicketPeriodGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items;
    } else {
      return [];
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: PaidTicketPeriodGetRequest) {
    const getResponse = await PaidTicketPeriodAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PaidTicketPeriodGetResponse
    };
  }
}

export default getModule(Get);
