import service from "@/api/service";
import { PaidTicketInfoGetResponse } from "@/api/analysis/paid-ticket-info/response";
import { PaidTicketInfoGetRequest } from "@/api/analysis/paid-ticket-info/request";

/**
 * デジタルチケット情報取得APIをコールします。
 *
 * @param getRequest デジタルチケット情報取得APIのリクエストボディ
 * @return PaidTicketInfoGetResponse
 */
export async function get(getRequest: PaidTicketInfoGetRequest) {
  const response = await service.post(
    "/get-analysis-paid-ticket-info",
    getRequest
  );
  return response.data as PaidTicketInfoGetResponse;
}
