import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as PaidTicketInfoAPI from "@/api/analysis/paid-ticket-info";
import { isSuccess } from "@/api/response";
import { PaidTicketInfoGetRequest } from "@/api/analysis/paid-ticket-info/request";
import { PaidTicketInfoGetResponse } from "@/api/analysis/paid-ticket-info/response";

const MODULE_NAME = "analysis/paid-ticket-info/get";

/**
 *  デジタルチケット情報取得API（/get-analysis-paid-ticket-info）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PaidTicketInfoGetRequest = {} as PaidTicketInfoGetRequest;
  getResponse: PaidTicketInfoGetResponse = {} as PaidTicketInfoGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items;
    } else {
      return [];
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: PaidTicketInfoGetRequest) {
    const getResponse = await PaidTicketInfoAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PaidTicketInfoGetResponse
    };
  }
}

export default getModule(Get);
